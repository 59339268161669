import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import Slides from "../components/slides"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO />
    <Slides />
    {/* <Image /> */}
  </Layout>
)

export default IndexPage
