import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

// import slick
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import "./slides.slick.css"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.com/docs/use-static-query/
 */

const Slides = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          sourceInstanceName: { eq: "images" }
          relativeDirectory: { eq: "slides" }
        }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            id
            extension
            dir
            name
            absolutePath
            relativePath
            publicURL
            childImageSharp {
              fluid(quality: 100, maxWidth:2000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
        }
      }
    }
  `)

  if (data?.allFile?.edges?.length === 0) {
    return <div>Slides not found</div>
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
  }

  return (
    <div className="slider">
      <Slider {...settings}>
        {data.allFile.edges.map((file, index) => {
          return (
            <div
              key={`slide-${index + 1}`}
              data-hash={`slide-${index + 1}`}
              // style={{ maxHeight: "calc(100vh - 1.75rem * 2)" }}
            >
              <Img
                key={file.node.id}
                alt={file.node.name}
                fluid={file.node.childImageSharp.fluid}
                fadeIn={false}
                style={
                  {
                    // maxHeight: "calc(100vh - 1.75rem * 2)",
                    // maxWidth: "calc(100vw - 25rem)",
                  }
                }
                imgStyle={
                  {
                    // objectFit: "contain",
                    // objectPosition: "bottom left",
                    // width: "auto",
                    // height: "auto",
                  }
                }
              />
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default Slides
